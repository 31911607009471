.messaging__channel-header {
  background: var(--str-chat__secondary-background-color);
  box-shadow: var(--messaging__header-shadow);
  transform: matrix(1, 0, 0, 1, 0, 0);
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  z-index: 1;
}

.str-chat-channel .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0 0 0;
}

.messaging__channel-header .channel-header__name {
  flex: 1;
  font-weight: bold;
  font-size: 15px;
  color: var(--str-chat__message-textarea-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
}

.str-chat.str-chat__theme-dark .messaging__channel-header {
  background: rgba(46, 48, 51, 0.98);
  box-shadow: var(--messaging__header-shadow);
}

.str-chat.str-chat__theme-dark .messaging__channel-header .channel-header__name {
  color: var(--str-chat__message-textarea-color);
}

.messaging__channel-header__right {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.messaging__channel-header .avatar-group {
  margin-right: 20px;
  margin-left: 20px;
}

.messaging__channel-header .avatar-group.four span:nth-child(2) {
  position: relative;
  right: 9px;
}

.channel-header__edit-input {
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  font-size: 17px;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.channel-header__edit-input::placeholder {
  opacity: 50%;
}

.str-chat.str-chat__theme-dark .channel-header__edit-input::placeholder {
  color: #ffffff;
}

.str-chat.str-chat__theme-dark .channel-header__edit-input {
  color: #ffffff;
}

#mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  #mobile-nav-icon {
    display: block;
    padding-left: 5px;
  }

  .str-chat__theme-light #mobile-nav-icon svg path {
    fill: darkslategrey;
    stroke: darkslategrey;
    fill-opacity: 60%;
  }

  .messaging__channel-header__avatars {
    margin-left: 10px;
  }
}
